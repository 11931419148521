import React, { useContext, useEffect, useState } from 'react';
import './Home.scss';
import DefaultBusinessContext from '../../business/BusinessContext';
import { CourseListItem } from '../../business/DataModel';
import Background from "../../assets/images/header.jpg";
import { CourseList } from '../CourseList';
import TechnicalError from '../TechnicalError';
import { createTechnicalErrorHandler, TechnicalErrorDetails } from '../../util';
import DefaultMetaTags from '../DefaultMetaTags';
// eslint-disable-next-line
import { Link } from 'react-router-dom';

const Home = () => {
  const context = useContext(DefaultBusinessContext);
  const [courses, setCourses] = useState(null as unknown as CourseListItem[]);
  const [loadingCourses, setLoadingCourses] = useState(false);
  const [loadingMyCourses, setLoadingMyCourses] = useState(false);
  const [myCourses, setMyCourses] = useState((context.user ? null : []) as unknown as CourseListItem[]);
  const [error, setError] = useState(null as unknown as TechnicalErrorDetails);

  useEffect(() => {
    if (null == courses && !loadingCourses) {
      setError(null as unknown as TechnicalErrorDetails);
      setLoadingCourses(true);
      context.getAllCourses()
        .then(courses => {
          setCourses(courses);
          setLoadingCourses(false)
        })
        .catch(createTechnicalErrorHandler(setError))
    }
    if (context.user && null == myCourses && !loadingMyCourses) {
      setLoadingMyCourses(true);
      context.getMyCourses()
        .then(courses => {
          setMyCourses(courses);
          setLoadingMyCourses(false);
        })
        .catch(e => setError((e && e.message) || e))
    }
  }, [courses, context, loadingCourses, loadingMyCourses, myCourses]);

  if (null != error && '' !== error) {
    return (
      <div className="container">
        <div className="mt-5">
          <TechnicalError error={error}/>
        </div>
      </div>
    )
  }
  return (
    <div className="home-view">
      <DefaultMetaTags/>
      {!context.user && <header>
        <div className="row">
          <div className="col">
            <div className="card text-white border-0">
              <img src={Background} className="img-fluid rounded-0" alt="..."/>
              <div className="card-img-overlay">
                <h1 className="display-4">Kursy Montessori</h1>
                <h2 className="lead">ONLINE</h2>
                <hr className="my-4"/>
                {/*<Link className="btn btn-primary btn-lg" to="/register" role="button">Zarejestruj się już dziś</Link>*/}
              </div>
            </div>
          </div>
        </div>
      </header>}
      <div className="container">
        {context.user && myCourses && 0 < myCourses.length && <CourseList courses={myCourses} title="Moje kursy" link="/me/courses"/>}
        <CourseList courses={courses} title="Wszystkie kursy"/>
      </div>
    </div>
  );
}

export default Home;
