import {
  CourseDetails,
  CourseListItem,
  Order,
  OrderLineItem,
  PaymentSessionToken,
  SearchRequest,
  SearchResult,
  User,
  UserRegistration,
  VideoSource
} from './DataModel';

import coursesData from '../assets/courses.json' ;
const courses = coursesData as CourseDetails[];

// DOCUMENTATION: https://montessori-course-backend.herokuapp.com/documentation

const BACKEND_URL = process.env.REACT_APP_API_URL || 'http://localhost:3333/api';

type AuthenticationResponse = {
  token: string
};

function handleError(response: Response) {
  if (299 < response.status) {
    throw response;
  }
}

function parseJson<T>(response: Response): Promise<T> {
  handleError(response);
  return response.json() as Promise<T>;
}

const getParamsString = (obj: any) => {
  let str = '';
  for (const key in obj) {
    if (!obj.hasOwnProperty(key) || null == obj[key]) {
      continue;
    }
    if ('' !== str) {
      str += '&';
    }
    str += `${key}=${encodeURIComponent(obj[key])}`;
  }
  return str;
};


export default class Api {
  authToken: string | undefined;

  _getHeaders(): Record<string, string> {
    const headers: Record<string, string> = { accept: 'application/json' };
    if (this.authToken) {
      headers.authorization = `Bearer ${this.authToken}`;
    }
    return headers;
  }

  async authenticate(email: string, password: string) {
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({ email, password })
    };
    const response = await fetch(`${BACKEND_URL}/users/authenticate`, requestOptions);
    const authenticationResponse = await parseJson<AuthenticationResponse>(response);
    this.setAuthToken(authenticationResponse.token);
  }

  async changePassword(currentPassword: string, newPassword: string) {
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: this._getHeaders(),
      body: JSON.stringify({ currentPassword, newPassword })
    };
    const response = await fetch(`${BACKEND_URL}/users/password`, requestOptions);
    const changePasswordResponse = await parseJson<AuthenticationResponse>(response);
    this.setAuthToken(changePasswordResponse.token)
  }

  async initPasswordReset(email: string) {
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: this._getHeaders(),
      body: JSON.stringify({ email })
    };
    await fetch(`${BACKEND_URL}/users/password/reset/init`, requestOptions);
  }

  async resetPassword(token: string, password: string) {
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: this._getHeaders(),
      body: JSON.stringify({ token, password })
    };
    const response = await fetch(`${BACKEND_URL}/users/password/reset`, requestOptions);
    const resetPasswordResponse = await parseJson<AuthenticationResponse>(response);
    this.setAuthToken(resetPasswordResponse.token)
  }

  updateUser(user: User) {
    const init: RequestInit = {
      method: 'PUT',
      headers: this._getHeaders(),
      body: JSON.stringify(user)
    };
    return fetch(`${BACKEND_URL}/users/${user.id}`, init).then(parseJson);
  }

  async getCourseById(id: string): Promise<CourseDetails> {
    // const requestOptions: RequestInit = {
    //   method: 'GET',
    //   headers: this._getHeaders()
    // };
    // const response = await fetch(`${BACKEND_URL}/courses/${id}`, requestOptions);
    // return parseJson(response);
    let course = courses.find((c) => c.id === id);
    if (!course) {
        throw new Error("Course not found");
    }
    return course
  }

  async getLessonVideoUrls(courseId: string, lessonId: number): Promise<VideoSource[]> {
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: this._getHeaders()
    };
    const response = await fetch(`${BACKEND_URL}/courses/${courseId}/lesson/${lessonId}/video-urls`, requestOptions);
    return parseJson(response);
  }

  async getOrderById(id: string): Promise<Order> {
    const requestOptions: RequestInit = {
      method: 'GET',
      headers: this._getHeaders()
    };
    const response = await fetch(`${BACKEND_URL}/orders/${id}`, requestOptions);
    return parseJson(response);
  }

  async getMyUserProfile(): Promise<User> {
    const init: RequestInit = { headers: this._getHeaders() };
    const response = await fetch(`${BACKEND_URL}/users/me`, init);
    return parseJson(response);
  }

  async markLessonCompleted(courseId: string, lessonId: number): Promise<void> {
    const init: RequestInit = {
      method: 'POST',
      headers: this._getHeaders()
    };
    const response = await fetch(`${BACKEND_URL}/courses/${courseId}/lesson/${lessonId}/completed`, init);
    handleError(response);
  }

  async setLessonAsCurrent(courseId: string, lessonId: number): Promise<void> {
    const init: RequestInit = {
      method: 'POST',
      headers: this._getHeaders()
    };
    const response = await fetch(`${BACKEND_URL}/courses/${courseId}/lesson/${lessonId}/current`, init);
    handleError(response);
  }

  async submitContactForm(token: string, from: string, text: string): Promise<void> {
    const init: RequestInit = {
      method: 'POST',
      headers: this._getHeaders(),
      body: JSON.stringify({ token, from, text })
    };
    const response = await fetch(`${BACKEND_URL}/contact`, init);
    handleError(response);
  }

  async submitOrder(lineItems: OrderLineItem[]): Promise<Order> {
    const init: RequestInit = {
      method: 'POST',
      headers: this._getHeaders(),
      body: JSON.stringify(lineItems.map(({ id, quantity }) => ({ id, quantity })))
    };
    const response = await fetch(`${BACKEND_URL}/orders`, init);
    return parseJson(response);
  }

  async registerTransaction(order: Order): Promise<string> {
    const init: RequestInit = {
      method: 'POST',
      headers: this._getHeaders(),
    };
    const response = await fetch(`${BACKEND_URL}/orders/${order.id}/register-transaction`, init);
    const parsedResponse: PaymentSessionToken = await parseJson(response);
    return parsedResponse.token;
  }

  async registerUser(data: UserRegistration): Promise<User> {
    const init: RequestInit = {
      method: 'POST',
      headers: this._getHeaders(),
      body: JSON.stringify(data)
    };
    const response = await fetch(`${BACKEND_URL}/users`, init);
    return parseJson(response);
  }

  setAuthToken(authToken: string | undefined) {
    this.authToken = authToken;
  }

  async searchCourses({ size }: SearchRequest): Promise<SearchResult<CourseListItem>> {
    return {total: courses.length, results: courses};
    // const requestOptions: RequestInit = {
    //     method: 'GET',
    //     headers: this._getHeaders()
    // };
    // const params = {size};
    // const response = await fetch(`${BACKEND_URL}/courses?${getParamsString(params)}`, requestOptions);
    // return parseJson(response);
  }

  async searchMyCourses(): Promise<SearchResult<CourseListItem>> {
    const requestOptions: RequestInit = {
      method: 'GET',
      headers: this._getHeaders()
    };
    const params = { owned: true };
    const response = await fetch(`${BACKEND_URL}/courses?${getParamsString(params)}`, requestOptions);
    return parseJson(response);
  }

  async searchOrders(): Promise<SearchResult<Order>> {
    const requestOptions: RequestInit = {
      method: 'GET',
      headers: this._getHeaders()
    };
    const response = await fetch(`${BACKEND_URL}/orders`, requestOptions);
    return parseJson(response);
  }

  signOut() {
    const requestOptions: RequestInit = {
      method: 'POST',
      headers: this._getHeaders()
    };
    return fetch(`${BACKEND_URL}/users/sign-out`, requestOptions)
      .then(handleError)
      .then(() => (delete this.authToken))
      .catch(e => {
        delete this.authToken;
        throw e;
      });
  }

  getUser(id: string) {
    const init: RequestInit = { headers: this._getHeaders() };
    return fetch(`${BACKEND_URL}/users/${id}`, init)
      .then(parseJson);
  }
}

export { BACKEND_URL };
